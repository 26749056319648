import React, { useEffect, useState } from "react";
import "./privacyandterms.css";
import bg from "../../assets/Images/bg.mp4";
import SignUp from "../../Components/Popups/SignUpModal/SignUp";
import Header from "../../Components/Header/Header";
import Loader from "../../Components/Loader/Loader";

const TermsAndConditions = () => {
  const [signUpPopup, setSignUpPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [5000]);
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      <div className="terms_container">
        <Header signup={() => setSignUpPopup(true)} showbtn={true} />
        <video src={bg} autoPlay loop muted />
        <SignUp open={signUpPopup} onClose={() => setSignUpPopup(false)} />

        <div className="terms_content">
          <div className="peragraphs">
            <h2>Terms and Conditions for Evolune Gaming</h2>
            <div className="last_updated">
              <p>Last updated: February 10, 2023</p>
            </div>
            <div className="welcome_text">
              <p>
                Welcome to Evolune Gaming (hereafter referred to as "Evolune,"
                "we," "us," or "our"), a gaming company located in The
                Netherlands. These terms and conditions (the "Terms") govern
                your access to and use of our website, products, and services
                (collectively, the "Services").
              </p>
            </div>

            <div className="term1">
              <h3>1.Acceptance of Terms</h3>
              <p>
                By accessing or using the Services, you agree to be bound by
                these Terms, as well as our Privacy Policy, which is
                incorporated into these Terms by reference. If you do not agree
                to these Terms, you may not access or use the Services.
              </p>
            </div>
            <div className="term2">
              <h3>2.Changes to Terms</h3>
              <p>
                We reserve the right to modify these Terms at any time, and such
                changes will be effective immediately upon posting the modified
                Terms on our website. Your continued use of the Services
                following the posting of any changes to these Terms constitutes
                acceptance of those changes.
              </p>
            </div>
            <div className="term3">
              <h3>3.Eligibility</h3>
              <p>
                The Services are intended solely for users who are 16 years of
                age or older, and who are legally able to enter into a binding
                agreement. By using the Services, you represent and warrant that
                you meet all of the foregoing eligibility requirements.
              </p>
            </div>
            <div className="term4">
              <h3>4.User Accounts</h3>
              <p>
                In order to use certain features of the Services, you may need
                to create an account. You are responsible for maintaining the
                confidentiality of your account information, including your
                password, and for all activity that occurs under your account.
                You agree to immediately notify us of any unauthorized use of
                your account.
              </p>
            </div>
            <div className="term5">
              <h3>5.User Conduct</h3>
              <p>
                You agree that you will not engage in any of the following
                prohibited activities:
              </p>
              <p>
                Infringing upon the intellectual property rights of Evolune or
                any third party. Posting or transmitting any malicious software
                or other harmful content. Engaging in any conduct that is
                unlawful, misleading, malicious, or discriminatory. Interfering
                with the proper functioning of the Services. Intellectual
                Property The content and technology used to provide the
                Services, including but not limited to software, graphics, text,
                images, logos, and trademarks, are owned by or licensed to
                Evolune and are protected by intellectual property laws. You may
                not use any of the foregoing materials except as necessary for
                your personal, non-commercial use of the Services.
              </p>
            </div>
            <div className="disclaimer">
              <h3>Disclaimer of Warranties</h3>
              <p>
                the services are provided "as is" without warranty of any kind,
                either express or implied, including without limitation any
                warranty for information, services, uninterrupted access, or
                products provided through or in connection with the services,
                including without limitation the software licensed to you and
                the results obtained through the services. specifically, we
                disclaim any and all warranties, including without limitation:
                1) any warranties concerning the availability, accuracy,
                usefulness, or content of information, and 2) any warranties of
                title, warranty of non-infringement, warranties of
                merchantability or fitness for a particular purpose.
              </p>
              <p>
                this disclaimer of liability applies to any damages or injury
                caused by any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, computer virus, communication line failure, theft
                or destruction or unauthorized access
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
