import React from "react";
import "./header.css";
import { ReactComponent as Logo } from "../../assets/Logo/logo.svg";
import { ReactComponent as FBIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TWIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as SendIcon } from "../../assets/Icons/send.svg";
import { ReactComponent as GFIcon } from "../../assets/Icons/gameface.svg";
import { ReactComponent as YTIcon } from "../../assets/Icons/youtube.svg";
import { ReactComponent as IGIcon } from "../../assets/Icons/instagram.svg";

const Header = ({ signup, showbtn }) => {
  return (
    <header className="header">
      <div className="header_content">
        <div className="header_col1">
          <a href="/">
            <Logo />
          </a>
        </div>
        <div className="header_col2">
          {showbtn ? null : (
            <div className="header_whitepaper_btn">
              <a href="/evolune.pdf" download>
                whitepaper
              </a>
            </div>
          )}
          <ul className="header_social_icons">
            <li className="header_icon">
              <a
                target="_blank"
                href="https://www.instagram.com/evolune_verse/"
              >
                <IGIcon className="header_social_icon" />
              </a>
            </li>
            {/* <li className="header_icon">
              <a href="#">
                <SendIcon className="header_social_icon" />
              </a>
            </li> */}
            <li className="header_icon">
              <a
                target="_blank"
                href="https://discord.com/api/oauth2/authorize?client_id=1080167659682943018&redirect_uri=https%3A%2F%2Flanding.evolune.co%2Floading&response_type=token&scope=identify%20email"
              >
                <GFIcon className="header_social_icon" />
              </a>
            </li>
            <li className="header_icon">
              <a target="_blank" href="https://www.youtube.com/@evolune4100">
                <YTIcon className="header_social_icon" />
              </a>
            </li>
            {/* <li className="header_icon">
              <a target="_blank" href="https://www.facebook.com/">
                <FBIcon className="header_social_icon" />
              </a>
            </li> */}
            <li className="header_icon">
              <a target="_blank" href="https://twitter.com/evoluneverse/">
                <TWIcon className="header_social_icon" />
              </a>
            </li>
          </ul>
          {showbtn ? (
            <div className="header_create_btn">
              <a href="#" onClick={signup}>
                join whitelist
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
