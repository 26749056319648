import React, { useEffect } from "react";
import "./loader.css";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Loader = () => {
  const navigate = useNavigate();
  const sendData = (data) => {
    console.log(data);
    axios
      .post("https://backendevolune.co:8080/discorduser/", {
        discordId: data.id,
        username: data.username,
        email: data.email,
        avatar: data.avatar,
        discriminator: data.discriminator,
        verified: data.verified,
      })
      .then((res) => {
        console.log(res);
        window.location.href = "https://discord.gg/tBqJJbfK63";
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (window.location.hash) {
      const fragment = new URLSearchParams(window.location.hash.slice(1));
      const [accessToken, tokenType] = [
        fragment.get("access_token"),
        fragment.get("token_type"),
      ];
      axios
        .get("https://discord.com/api/users/@me", {
          headers: {
            authorization: `${tokenType} ${accessToken}`,
          },
        })
        .then((res) => {
          sendData(res.data);
        })
        .catch((e) => {
          console.log(e);
          navigate("/");
        });
    }
  }, []);
  return (
    <div className="loader_container">
      <div className="loader_text">
        <span>Loading</span>
        <BeatLoader
          color="#C195DB"
          aria-label="Loading Spinner"
          data-testid="loader"
          className="loader_dot"
        />
      </div>
    </div>
  );
};

export default Loader;
