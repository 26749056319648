import { Route, Routes } from 'react-router-dom';
import './App.css';
import Loader from './Components/Loader/Loader';
import AboutUs from './Screens/AboutUs/AboutUs';
import Home from './Screens/Home';
import PrivacyPolicy from './Screens/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Screens/PrivacyPolicy/TermsAndConditions';
import ThankYou from './Screens/ThankYouPage/ThankYou';

function App() {

  return (
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
    <Route path='/terms' element={<TermsAndConditions/>}/>
    <Route path='/thankyou' element={<ThankYou/>}/>
    <Route path='/loading' element={<Loader/>}/>
    </Routes>
  );
}

export default App;
