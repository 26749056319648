import React, { useEffect, useState } from "react";
import "./thankyou.css";
import bg from "../../assets/Images/bg.mp4";
import Header from "../../Components/Header/Header";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [4000]);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="ty_container">
        <Header />
        <video src={bg} autoPlay loop muted />
        <div className="ty_content">
          <div className="ty_text">
            <h2>
              Thank and welcome to the Evolune Metaverse. We have added you to
              the Whitelist and you will receive regular update and your first
              Free NFT.
              <br />
              See you in the Evolune Metaverse soon
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
