import React from "react";
import "./video.css";
import gameplay from "../../assets/videos/gameplay.mp4";
import gameplayPoster from "../../assets/Images/gameplay-poster.png";
export const Video = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <>
      <div className="popup_overlay">
        <div className="gameplayvideo_container">
        <p className="close_btn video_close_btn" onClick={onClose}>X</p>
          <div className="gameplayvideo_content">
            {/* <video src={gameplay} controls></video> */}

            <video poster={gameplayPoster} controls>
              <source src={gameplay} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};
