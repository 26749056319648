import React, { useState } from "react";
import "../popup.css";
import { ReactComponent as Email } from "../../../assets/Icons/email.svg";
import { ReactComponent as Password } from "../../../assets/Icons/password.svg";
import { ReactComponent as Username } from "../../../assets/Icons/username.svg";
import { ReactComponent as EyeClose } from "../../../assets/Icons/eyeclose.svg";

import axios from "axios";
import { Link } from "react-router-dom";

const SignUp = ({ open, onClose }) => {
  const [username, setUserName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [thMsg, setThMsg] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    email,
    username,
  });

  //////UPDATING BACKEND USER////
  const signUp = () => {
    if (!email || !password || !username) {
      alert("Please Input All The Fields");
    } else {
      axios
        .post("https://backendevolune.co:8080/api/auth/signup", {
          email,
          username,
          password,
        })
        .then((res) => {
          let userInfo = res.data;
          console.log(userInfo);
          setThMsg(true);
        })
        .catch((e) => {
          console.log(`regiter error ${e}`);
          const errorMsg = e.response.data.message;
          if (errorMsg === "Failed! Email is already in use!") {
            console.log("Email error occur");
            setErrorMessage({ email: "Email is already in use" });
          } else if (errorMsg === "Failed! Username is already in use!") {
            console.log("Username error occur");
            setErrorMessage({ username: "Username is already in use" });
          }
        });
    }
  };

  if (!open) return null;
  return (
    <div className="popup_overlay">
      <div className="modal_container">
        <p className="close_btn" onClick={onClose}>
          X
        </p>
        <div className="model_content">
          <form action="#" id="signUp_form">
            <div className="form_heading">
              <h2>create account</h2>
            </div>
            {thMsg ? (
              <div className="success_msg">
                <h3>
                  thank you for creating an account please check your inbox
                </h3>
              </div>
            ) : (
              <>
                <div className="create_btn" style={{ paddingTop: "30px" }}>
                  <a href="https://discord.com/api/oauth2/authorize?client_id=1080167659682943018&redirect_uri=https%3A%2F%2Flanding.evolune.co%2Floading&response_type=token&scope=identify%20email">
                    {/* <a href="https://discord.gg/tBqJJbfK63"> */}
                    join with discord
                  </a>
                </div>
                <div className="form_fields">
                  <div className="email_input">
                    <Email />
                    <input
                      type="email"
                      name="email"
                      required
                      placeholder="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <p style={{ color: "red" }}>{errorMessage.email}</p>
                  <div className="password_input">
                    <label>
                      <Password />
                      <input
                        type={showPass ? "text" : "password"}
                        name="text"
                        required
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </label>
                    <EyeClose
                      onClick={() => setShowPass(!showPass)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p>Minimum 8 characters</p>
                  <div className="username_input">
                    <Username />
                    <input
                      type="text"
                      name="username"
                      required
                      placeholder="username"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <p style={{ color: "red" }}>{errorMessage.username}</p>
                </div>
              </>
            )}
            <div className="form_footer">
              {thMsg ? null : (
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="checkboxSU"
                    style={{ opacity: isChecked ? 1 : 0 }}
                  />
                  <label
                    htmlFor="checkboxSU"
                    onClick={() => setIsChecked(!isChecked)}
                  ></label>
                  <span>I want exclusive Items and email Updates</span>
                </div>
              )}
              <div className="policy_content">
                <p> By clicking Create Account, you are agreeing to our </p>
                <p>
                  <Link to="/terms">
                    <span>Terms & Conditions</span>
                  </Link>
                  and
                  <Link to="/privacypolicy">
                    <span>Privacy Policy</span>
                  </Link>
                </p>
              </div>

              {thMsg ? null : (
                <div className="create_btn">
                  <a href="#" onClick={() => signUp()}>
                    join whitelist
                  </a>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
