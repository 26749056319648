import React, { useEffect, useState } from "react";
import "./home.css";
import Header from "../Components/Header/Header";
import AvatarImg1 from "../assets/Images/avatar1.png";
import AvatarImg2 from "../assets/Images/avatar2.png";
import bg from "../assets/Images/bg.mp4";
import Logo from "../assets/Images/logo.png";
import SignUp from "../Components/Popups/SignUpModal/SignUp";
import { ReactComponent as FBIcon } from "../assets/Icons/facebook.svg";
import { ReactComponent as TWIcon } from "../assets/Icons/twitter.svg";
import { ReactComponent as SendIcon } from "../assets/Icons/send.svg";
import { ReactComponent as GFIcon } from "../assets/Icons/gameface.svg";
import { ReactComponent as YTIcon } from "../assets/Icons/youtube.svg";
import { ReactComponent as IGIcon } from "../assets/Icons/instagram.svg";
import { ReactComponent as VideoPlayIcon } from "../assets/Icons/videoplay.svg";
import Loader from "../Components/Loader/Loader";
import AboutUs from "./AboutUs/AboutUs";
import { Video } from "../Components/Video/Video";

const Home = () => {
  const [signUpPopup, setSignUpPopup] = useState(false);
  const [videoPopup, setVideoPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(document.readyState);
    setTimeout(() => {
      if (document.readyState === "complete") {
        setIsLoading(false);
        console.log(document.readyState);
      }
    }, [5000]);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="home_container">
        <video src={bg} autoPlay loop muted />
        <SignUp open={signUpPopup} onClose={() => setSignUpPopup(false)} />
        <Video open={videoPopup} onClose={() => setVideoPopup(false)}/>

        <Header />
        <div className="home_content">
          <div className="shadowbox_bottom"></div>
          <div className="avatar1">
            <img src={AvatarImg1} alt="" loading="lazy" />
          </div>
          <div className="home_middle_content">
          <div className="videoplay_btn">
              <a href="#" onClick={() => setVideoPopup(true)}>
                <VideoPlayIcon width="100%" height="100%" />
              </a>
            </div>
            <div className="middle_text">
              <h2>Imagine, Create, and Own</h2>
              <h2> Play the Game and Build Your Dreams, for Free!</h2>
              <p className="spacer"></p>
              <p>
                Join the whitelist to test the game and earn an Evolune NFT
                reward.
              </p>
            </div>
            
            
            <div className="create_btn">
              <a href="#" onClick={() => setSignUpPopup(true)}>
                join whitelist
              </a>
            </div>
          </div>
          <div className="avatar2">
            <img src={AvatarImg2} alt="" />
          </div>
        </div>
        <div className="social_icons">
          <ul className="home_social_icons">
            <li className="home_icon">
              <a
                target="_blank"
                href="https://www.instagram.com/evolune_verse/"
              >
                <IGIcon />
              </a>
            </li>
            {/* <li className="home_icon">
            <a href="#">
              <SendIcon />
            </a>
          </li> */}
            <li className="home_icon">
              <a href="https://discord.com/api/oauth2/authorize?client_id=1080167659682943018&redirect_uri=https%3A%2F%2Flanding.evolune.co%2Floading&response_type=token&scope=identify%20email">
                <GFIcon />
              </a>
            </li>
            <li className="home_icon">
              <a target="_blank" href="https://www.youtube.com/@evolune4100">
                <YTIcon />
              </a>
            </li>
            {/* <li className="home_icon">
              <a target="_blank" href="https://www.facebook.com/">
                <FBIcon />
              </a>
            </li> */}
            <li className="home_icon">
              <a target="_blank" href="https://twitter.com/evoluneverse">
                <TWIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <AboutUs />
    </>
  );
};

export default Home;
