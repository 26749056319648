import React from "react";
import "./aboutus.css";
import Col2Img from "../../assets/Images/aboutusImg.png";

const AboutUs = () => {
  return (
    <div className="aboutus_container">
      <div className="aboutus_content">
        <div className="aboutus_col1">
          <h1>about us</h1>
          <p>
            We are a blockchain-based gaming platform allowing gamers,
            developers, and investors to come together and experience a new era
            of blockchain gamification. We offer free-to-own NFTs that can be
            earned by participating in our games and exploring the worlds we
            (and other creators) have created.
          </p>
          <p>
            Our platform provides a gamified ecosystem that allows you to engage
            deeper with your audience, creating immersive worlds like never
            before. Creators can get hands-on with our sandbox tools, gamify
            their ideas and successfully monetize them.
          </p>
          <p>
            We strongly believe in owning your assets. That’s why we made
            trading Evolune NFTs very easy. Explore our Marketplace section and
            find everything you need. An incentive-based model creates rewards
            for players, developers, and investors who trade their Evolune
            assets using our native NFT marketplace.
          </p>
          <p>
            Join us and become part of the new era of blockchain gamification.
            Start your sci-adventure today and enjoy the thrill of Evolune's
            adventure.
          </p>
        </div>
        <div className="aboutus_col2">
          <img src={Col2Img} alt="" />
        </div>
      </div>
      <div className="copyright">
        <p>© 2023 Evolune</p>
      </div>
    </div>
  );
};

export default AboutUs;
